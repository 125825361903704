<template>
    <!-- 定义标签 -->
    <div
    v-resize="resize"
      :id="id"
      :style="{
        width: width,
        height: height,
      }"
    ></div>
  </template>
  
  <script>
  import * as echarts from "echarts";
  import { myMixin } from "../utils/mixin.js";
  // 2.生成一个 id 值给标签，目的是为了当多次使用组件防止id重复
  const uid = function () {
    return new Date().getTime();
  };
  export default {
    mixins: [myMixin],
    //1.获取传过来的值
    props: {
      width: {
        typeof: String,
      },
      id: {
        typeof: String,
      },
      height: {
        typeof: String,
      },
      option: {
        typeof: Object,
        default() {
          return null;
        },
      },
    },
    data() {
      return {
        myChart: null,
      };
    },
    created() {
    },
    mounted() {
      //   5. 创建echarts图表实例
      this.myChart = echarts.init(document.getElementById(this.id));
      // 指定图表的配置项和数据
      var option = this.option;
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
      var that = this;
    // window.onresize = function () {
    //   that.myChart.resize();
    // };
    },
  };
  </script>
  