<template>
	<div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft">
				<div class="swiperBox">
					<el-carousel>
						<el-carousel-item v-for="(item, index) in bnnerLists" :key="index">
							<img
								:src="item.pic"
								:key="item.pic"
								alt=""
								style="object-fit: cover"
							/>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="rsTitle flex align_items_c margin_top_30">
					<div class="rsTitleLeft flex align_items_c">
						<div style="color: #000000; font-weight: bold">各地政策</div>
						<div
							class="flex align_items_c pointer"
							@click="
								$router.push({
									path: '/gw_dex',
									query: { id: 5, name: '各地政策' },
								})
							"
						>
							更多
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
				<div class="flex justify_content_sb" style="margin-top: 18px">
					<!-- <img src="https://zy.metaera.media/assets/images/cy/1.png" class="zcImg" alt="" /> -->
					<div class="zcList">
						<div
							class="zcItem pointer"
							v-for="(item, index) in labelDetails"
							:key="index"
							@click="
								$router.push({
									path: '/gw_detail',
									query: { id: item.id },
								})
							"
						>
							<div class="ellipsis_one hovercolor">{{ item.title }}</div>
							<div v-if="item.author !== null">
								{{ item.author.nickname }} . {{ item.release_time }}
							</div>
							<div v-else>{{ item.release_time }}</div>
						</div>
					</div>
				</div>
				<div class="tageBox">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane
							:label="item.name"
							:name="item.id + ''"
							v-for="(item, index) in categoryLists"
							:key="index"
						>
							<div v-if="articleList.length > 0">
								<div
									class="wzitem flex align_items_c pointer"
									v-for="(items, indexs) in articleList"
									:key="indexs"
									@click="
										$router.push({
											path: '/gw_detail',
											query: { id: items.id },
										})
									"
								>
									<img :src="items.pic" alt="" style="object-fit: cover" />
									<div class="wzitemL">
										<div class="wzitemLTitle">
											<div
												style="color: #000000; font-weight: bold"
												class="ellipsis_one hovercolor"
											>
												{{ items.title }}</div
											>
											<div>{{ items.illustrate }}</div>
										</div>
										<div
											class="wzitemLTime flex align_items_c justify_content_sb"
										>
											<div class="wzitemLTime_l"
												>MetaEra · <span>{{ items.release_time }}</span></div
											>
											<div class="flex align_items_c lookNum">
												<img
													src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH5SURBVHgB7VXBUQJBEJxZqiykfFwGnhGIEQgZyEMt/HAbgRgBEIESwcLLAh+QgWQARCBmwAstq7h15m454Gr3OP7Mi73d6Z7p7R0ATnEgMM8hpd59AFEGCL34i1hS6kLKh9mhXHSDKk+Ii2etw4CWvuPYgiAmWq87UtYXuQn6/Y+W1roJoL0U0HeUhOLakJYTIBTtRuO+k0nAUiAWFAFXeE0kXZKj55Iilg7aBN4wn2bUcW23G9wHF59cGQHPCTjIo3E6l7slkuqGBOMDykMsTbfgPxUp5RKOCKVGhPHHJOW4k1WVMUTEgqXWIXAugioNlBrc2QikrC21PqsyOJMYTEClhrTQ03Rr6ej1Bl+wdROdXd3YC4nuccoGYTyBCK+8QQun1UzV/s4n+l10dFLnQiM3EVFLbLfQA2eElj2dcUfbPcGPJGbDlrGdJYpjiN5CEvSKn8a2k8ZRkf5aw4ugliax34FcIEZ8mekkvsAgeLyihBq1L1l/OzjnIhOzYbpsc2NTu8XgiIjBzyekBL/yxARiUyG/QCMDW2zqlssGPizvg4dJgZZRIUaQzBjsZQ0yY3EeE01znueVtI6KFFF7c1EmZqayeZSEhUszr3wDQwqsSfN6O42VMa6TQXYLGeOaSPsAv2+uO8v5h8Maa3//DyecuaQ7xVHxD4rDJjfZUTFuAAAAAElFTkSuQmCC"
													alt=""
													style="object-fit: cover"
												/>
												<div>{{ items.like_num }}</div>
											</div>
										</div>
									</div>
								</div>

								<pagination
									v-show="total > 0"
									:total="total"
									:page.sync="page"
									:limit.sync="size"
									@pagination="getDataListPage"
								/>
							</div>
							<!-- <noData v-else></noData> -->
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import chart from "@/components/echart.vue";
import {
	homeLinsts,
	getLabelDetails,
	getArticleList,
	categoryLists,
} from "@/api/home";
export default {
	name: "",
	components: {
		chart,
	},
	data() {
		return {
			activeName: "0",
			articleList: [],
			categoryLists: [],
			selarticleList: [],
			bnnerLists: [],
			labelDetails: [],
			option: {
				legend: {},
				tooltip: {},
				dataset: {
					source: [
						["1月", 4],
						["2月", 8],
						["3月", 8],
						["4月", 3],
					],
				},
				xAxis: {
					type: "category",
					splitLine: {
						show: false,
					},
				},
				yAxis: {
					axisTick: {
						//设置刻度线样式为不显示
						show: false,
					},
					axisLabel: {
						//设置标签颜色为透明
						color: "transparent",
					},
					splitLine: {
						show: false,
					},
				},
				series: [
					{
						type: "bar",
						itemStyle: {
							// 柱状图颜色
							color: "#86C56A",
						},
					},
				],
			},
			page: 1,
			size: 25,
			total: "",
		};
	},
	created() {
		this.getLabelDetails();
		this.getDataList();
		//   this.getArticleList({ category_id: 0, type: 1, page: 1, size: 25 }, 0)
	},
	methods: {
		getDataListPage() {
			this.getArticleList(
				{
					category_id: this.activeName,
					type: 4,
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getLabelDetails() {
			getLabelDetails({ id: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.labelDetails = res.data.data.list.splice(0, 3);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getDataList() {
			homeLinsts({})
				.then((res) => {
					if (res.code == 200) {
						this.bnnerLists = res.data.bnnerLists;
						this.getCategortLists();
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		getCategortLists() {
			categoryLists({ type: 4 })
				.then((res) => {
					if (res.code == 200) {
						this.categoryLists = res.data;
						if (res.data.length > 0) {
							this.activeName = res.data[0].id;
							this.getArticleList(
								{
									category_id: res.data[0].id,
									type: 4,
									page: this.page,
									size: this.size,
								},
								0
							);
						}
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},

		handleClick(val) {
			this.page = 1;
			this.getArticleList(
				{ category_id: val.name, type: 4, page: this.page, size: this.size },
				0
			);
		},
		getArticleList(obj, type) {
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.articleList = res.data.list;
						// console.log(this.articleList);
						this.total = res.data.total;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 600px;
}

.swiperBox {
	width: 100%;
	height: 300px;
}

.swiperBox img {
	width: 100%;
	height: 100%;
}

.zcImg {
	width: 360px;
	border-radius: 20px;
}

.zcList {
	width: 360px;
	height: 180px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.zcItem div:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.zcItem div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
	margin-top: 7px;
}

.tageBox {
	margin-top: 57px;
}

.MainRight {
	width: 360px;
}

.cytzChart {
	height: 293px;
}

.zxItem {
	width: 90%;
	margin: 0 auto;
}

.zxItemTitle {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.qyml {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.qymlItem {
	width: 177px;
	height: 62px;
	background: rgba(255, 255, 255, 0);
	border-radius: 3px 3px 3px 3px;
	opacity: 1;
	border: 1px solid #f7f7f7;
	margin-top: 12px;
}

.swhz {
	width: 360px;
	height: 64px;
	margin-top: 22px;
}
</style>
<style>
.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
</style>
